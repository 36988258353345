import { useCallback, useState } from "react";
import SelectDropDownAsync from "../../components/FormikComponents/SelectDropDownAsync";
import { Form, Formik } from "formik";
import Select from "../../components/FormikComponents/Select";
import Input from "../../components/FormikComponents/Input";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import DetailedInfo from "../../components/Accordian-Cards/DetailedInfo";
import Collapsible from "../../components/Collapsible/Collapsible";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { SUCCESS } from "../../types/constants";
import { hideModal } from "../../redux/features/modalSlice";
import { AxiosError } from "axios";
import {
    ArtisanData,
    BiomassSupplierData,
    BuyerData,
    FPOData,
    SequesterData,
    TrackingData,
} from "../../types";

interface ValuesProp {
    trackThrough: string;
    trackingId: string;
}

// Time conversions
const convertDateFormat = (utcTime: string) => {
    // Create a Date object from the UTC time string
    const utcDate = new Date(utcTime);

    // Format the IST date as "05:01:42 PM 05-10-2024"
    const istTime = utcDate.toLocaleDateString("en-US", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        day: "numeric",
        month: "numeric",
        year: "numeric",
        timeZone: "UTC",
    });

    return istTime;
};

function TrackAndTrace() {
    const dispatch = useDispatch();
    const [, setIsSubmitting] = useState(false);
    const [, setCommonError] = useState("");
    const [, setShowForm] = useState(true);

    const [productData, setProductData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const queryClient = useQueryClient();
    const validationSchema = Yup.object({
        trackThrough: Yup.string().required("Required"),
        trackingId: Yup.string()
            .required("Required")
            .min(8, "Invalid Tracking ID"),
    });
    let initialValues: ValuesProp = { trackThrough: "", trackingId: "" };

    const handleSubmit = useCallback(
        (
            values: ValuesProp,
            {
                setSubmitting,
            }: { setSubmitting: (isSubmitting: boolean) => void }
        ) => {
            setIsSubmitting(true);
            setCommonError("");

            try {
                const obj = {
                    trackThrough: values.trackThrough,
                    trackingId: values.trackingId,
                };
                if(values?.trackThrough !== "buyer") {
                    setSearchQuery(values.trackingId);
                }
                axiosInstance
                    .post(`/admin/track`, obj, {
                        headers: {
                            ContentType: "application/json",
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsSubmitting(false);
                            setSubmitting(false);
                            setProductData(response.data?.data);
                            // console.log("productdata", productData)
                            dispatch(
                                addToast({
                                    kind: SUCCESS,
                                    msg: "Product Tracked Successfully!",
                                })
                            );
                            queryClient.invalidateQueries(["getAllUsers"]);
                            setShowForm(false);
                            dispatch(hideModal());
                        }
                    })
                    .catch((error: AxiosError) => {
                        setIsSubmitting(false);
                        setSubmitting(false);

                        if (error.response) {
                            const { msg } = error.response.data as {
                                msg: string;
                            };
                            switch (error.response.status) {
                                case 400:
                                case 403:
                                case 500:
                                    setCommonError(msg);
                                    break;
                                case 404:
                                    setCommonError(
                                        "No data found for the given Product"
                                    );
                                    break;
                                default:
                                    setCommonError(
                                        "Oops, something went wrong"
                                    );
                                    break;
                            }
                        } else if (error.request) {
                            setCommonError("Oops, something went wrong");
                        } else {
                            setCommonError(`Error: ${error.message}`);
                        }
                    });
            } catch (error) {
                setCommonError("Oops, something went wrong");
                setIsSubmitting(false);
                setSubmitting(false);
                return;
            }
        },
        [dispatch, queryClient]
    );

    const loadOptions = async (inputValue: string) => {
        const response = await axiosInstance.get(`/admin/users`);
        console.log(response);

        try {
            if (response.status === 200) {
                let responseData = [];
                for (let i = 0; i < response?.data?.data?.length; i++) {
                    if (
                        response.data.data[i].name
                            .toLowerCase()
                            .replace(" ", "")
                            .includes(inputValue.toLowerCase())
                    ) {
                        responseData.push({
                            label: response?.data?.data[i]?.name,
                            value: response?.data?.data[i]?.id,
                        });
                    }
                }
                return responseData;
            }
        } catch (error) {
            return [{ label: "No data found", value: "" }];
        }
    };

    return (
        <div className="users h-full w-full">
            <div className="content">
                <div className="all-users">
                    <header className="flex items-center justify-between my-8 flex-wrap">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {(props) => (
                                <Form
                                    className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-12"
                                    onSubmit={props.handleSubmit}
                                >
                                    <Select
                                        label="Product Type"
                                        id="ProductSearch"
                                        name="trackThrough"
                                        onChange={(e: Event) => {
                                            props.values.trackingId = "";
                                            props.handleChange(e);
                                        }}
                                        options={[
                                            {
                                                value: "buyer",
                                                label: "Buyer",
                                            },
                                            {
                                                value: "suncarbon_product",
                                                label: "Suncarbon Product",
                                            },
                                            {
                                                value: "biomass_batch",
                                                label: "Biomass Batch",
                                            },
                                            {
                                                value: "biochar",
                                                label: "Biochar",
                                            },
                                            {
                                                value: "sequestration",
                                                label: "Seqestration",
                                            },
                                        ]}
                                    />

                                    {props.values.trackThrough === "buyer" ? (
                                        <>
                                            <SelectDropDownAsync
                                                label="Buyer Name"
                                                id="trackingId"
                                                name="trackingId"
                                                loadOptions={loadOptions}
                                                setLabelText={setSearchQuery}
                                            />
                                        </>
                                    ) : (
                                        <Input
                                            name="trackingId"
                                            id="trackingId"
                                            label="Tracking ID"
                                            type="text"
                                            placeholder="XXXX XXXX"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props?.values?.trackingId}
                                        />
                                    )}

                                    <SubmitBtn
                                        text="Track & Trace"
                                        isSubmitting={props?.isSubmitting}
                                        classes=""
                                        containerClasses="flex justify-center items-center"
                                    />
                                </Form>
                            )}
                        </Formik>
                    </header>

                    {productData?.length === 0 ? (
                        <div className="mx-4 my-6 p-6 border border-gray-200 bg-white text-xl font-semibold rounded-xl shadow-xl">
                            No Product Data Found!!
                        </div>
                    ) : productData?.length > 1 ? (
                        <>
                            {productData?.map((data: any, index1: number) => (
                                // collapsible for each product

                                <Collapsible
                                    title={`Product: ${data?.sp_id}`}
                                    key={index1}
                                    content={
                                        <CollapsibleContent
                                            data={{ ...data, searchQuery }}
                                            key={index1}
                                        />
                                    }
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {productData?.map((data: any, index1: number) => (
                                // non collapsible for each product
                                <CollapsibleContent
                                    data={{ ...data, searchQuery }}
                                    key={index1}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

const CollapsibleContent = ({ data }: { data: TrackingData }): JSX.Element => {
    const { searchQuery } = data;
    const biomass_supplier_index = 1;
    const sequester_index =
        data?.biomass_suppliers?.length + biomass_supplier_index;
    const artisan_index = data?.sequesters?.length + sequester_index;
    const fpo_index = data?.artisans?.length + artisan_index;
    const buyer_index = data?.fpos?.length + fpo_index;

    return (
        <section className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {data?.biomass_suppliers?.map(
                (item: BiomassSupplierData, index2: number) => (
                    <DetailedInfo
                        address={item?.addresses ? item?.addresses[0] : null}
                        index={biomass_supplier_index + index2}
                        key={biomass_supplier_index + index2}
                        title="Biomass Suppliers"
                        searchQuery={searchQuery}
                        data={[
                            // {
                            //     label: "Biomass Supplier ID",
                            //     value: item?.biomass_supplier_id,
                            // },
                            {
                                label: "Owner Name",
                                value: item?.owner_name,
                            },
                            {
                                label: "Company Email",
                                value: item?.company_email,
                            },
                            {
                                label: "Company Mobile",
                                value: item?.company_mobile,
                            },
                            {
                                label: "Biomass Type",
                                value: item?.biomass_type,
                            },
                        ]}
                    />
                )
            )}

            {data?.sequesters?.map((item: SequesterData, index2: number) => (
                <DetailedInfo
                    index={sequester_index + index2}
                    key={sequester_index + index2}
                    title="Sequesters"
                    searchQuery={searchQuery}
                    address={item?.addresses ? item?.addresses[0] : null}
                    data={[
                        {
                            label: "Biomass Batch ID",
                            value: item?.biomass_batch_id,
                        },
                        {
                            label: "Batch Date",
                            value: convertDateFormat(item?.batch_date),
                        },
                        {
                            label: "Biochar ID",
                            value: item?.biochar_id || "None",
                        },
                        {
                            label: "Sequestration ID",
                            value: item?.sequestration_id,
                        },
                        {
                            label: "Incorporation Time",
                            value: convertDateFormat(item?.incorporation_time),
                        },
                        {
                            label: "Sequestration Type",
                            value: item?.sequestration_type.main_type
                                .replace("_", " ")
                                .toUpperCase(),
                        },
                        {
                            label: "SP ID",
                            value: item?.sp_id,
                        },
                    ]}
                />
            ))}

            {data?.artisans?.map((item: ArtisanData, index2: number) => (
                <DetailedInfo
                    index={artisan_index + index2}
                    title="Artisans"
                    key={artisan_index + index2}
                    searchQuery={searchQuery}
                    address={item?.addresses ? item?.addresses[0] : null}
                    data={[
                        {
                            label: "Artisan ID",
                            value: item?.artisan_id,
                        },
                        {
                            label: "Name",
                            value: item?.name,
                        },
                        {
                            label: "Company Email",
                            value: item?.company_email,
                        },
                        {
                            label: "Company Mobile",
                            value: item?.company_mobile,
                        },
                        {
                            label: "Status",
                            value: item?.status?.toUpperCase(),
                        },
                        {
                            label: "Address",
                            value: item?.address,
                        },
                    ]}
                />
            ))}

            {data?.fpos?.map((item: FPOData, index2: number) => (
                <DetailedInfo
                    address={item?.addresses ? item?.addresses[0] : null}
                    index={fpo_index + index2}
                    key={fpo_index + index2}
                    searchQuery={searchQuery}
                    title="FPOS"
                    data={[
                        {
                            label: "FPO ID",
                            value: item?.fpo_id,
                        },
                        {
                            label: "Name",
                            value: item?.fpo_name,
                        },
                        {
                            label: "FPO Email",
                            value: item?.fpo_email,
                        },
                        {
                            label: "FPO Mobile",
                            value: item?.fpo_number,
                        },
                        {
                            label: "GST Number",
                            value: item?.gst_number ? item?.gst_number : "NONE",
                        },
                        {
                            label: "CIN",
                            value: item?.cin,
                        },
                        {
                            label: "Is Active",
                            value: JSON.stringify(
                                item?.is_active
                            ).toUpperCase(),
                        },
                    ]}
                />
            ))}

            {data?.buyers?.map((item: BuyerData, index2: number) => (
                <DetailedInfo
                    address={item?.addresses ? item?.addresses[0] : null}
                    index={buyer_index + index2}
                    key={buyer_index + index2}
                    searchQuery={searchQuery}
                    title="Buyers"
                    data={[
                        {
                            label: "buyer_id",
                            value: item?.id,
                            hidden: true,
                        },
                        {
                            label: "Name",
                            value: item?.name,
                        },
                        {
                            label: "User Email",
                            value: item?.email,
                        },
                        {
                            label: "Phone Number",
                            value: item?.phonenumber,
                        },
                        {
                            label: "Transations",
                            value:
                                item?.transaction_dates
                                    ?.map((date: string) =>
                                        convertDateFormat(date)
                                    )
                                    .join(" <|> ") || "NONE",
                        },
                    ]}
                />
            ))}
        </section>
    );
};

export default TrackAndTrace;
