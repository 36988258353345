import React, { useCallback } from "react";
import { Formik, Form, Field, useField } from "formik";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Select from "../FormikComponents/Select";
import SelectDropDownAsync from "../FormikComponents/SelectDropDownAsync";
import Input from "../FormikComponents/Input";



type FormulationFormModalProps = {
  sequestration_ids: {value: string}[];
  material_id?: number ;
  material_qty: number;
  total_qty: number;
  product_name: string;
};

type FormulationFormObjProps = {
  sequestration_ids: string[];
  material_id?: number ;
  material_qty: number;
  total_batch_qty: number;
  product_name: string;
}


// const FormField: React.FC<{
//   label: string;
//   name: string;
//   type?: string;
//   as?: string;
//   multiple?: boolean;
//   children?: React.ReactNode;
//   disabled?: boolean;
//   placeholder?: string;
// }> = ({
//   label,
//   name,
//   type = "text",
//   as,
//   multiple,
//   children,
//   disabled,
//   placeholder,
// }) => {
//     const [field, meta] = useField(name);
//     return (
//       <div className="w-full">
//         <label htmlFor={name} className="block font-bold">
//           {label}
//         </label>
//         <Field
//           {...field}
//           id={name}
//           name={name}
//           type={type}
//           as={as}
//           multiple={multiple}
//           disabled={disabled}
//           placeholder={placeholder}
//           className="border border-slate-400 bg-white w-full p-2 rounded-lg"
//         >
//           {children}
//         </Field>
//         {name === "total_qty" && (
//           <div className="text-sm text-gray-500 mt-1">Read only</div>
//         )}
//         {meta.touched && meta.error ? (
//           <div className="text-red-500">{meta.error}</div>
//         ) : null}
//       </div>
//     );
//   };

const FormulationFormModal: React.FC<FormulationFormModalProps> = ({
  sequestration_ids,
  material_id,
  material_qty,
  total_qty,
  product_name,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const initialValues: FormulationFormModalProps = {
    sequestration_ids: [],
    material_id: undefined,
    material_qty: 0,
    total_qty: 0,
    product_name: "",
  };

  const validationSchema = Yup.object({
    material_qty: Yup.number().min(1, "Material Quantity should be greater than 0").required("Material Quantity is Required"),
    total_qty: Yup.number().min(1, "Total Quantity should be greater than 0").required("Total Quantity is Required"),
    product_name: Yup.string().required("Product Name is Required").min(3, "Product Name should be at least 3 characters long").max(50, "Product Name should be at most 50 characters long"),
  });

  const handleSubmit = useCallback(
    async (
      values: FormulationFormModalProps,
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
      try {
        const sequestration_ids_types = new Set();
        values?.sequestration_ids?.forEach((id) => {
          if(id.value.includes("SUN-CBAT")){
            sequestration_ids_types.add("CBAT");
          }
          else if(id.value.includes("SUN-SEQU")){
            sequestration_ids_types.add("SEQU");
          }
          else{
            sequestration_ids_types.add("OTHER");
          }
        });
        if(sequestration_ids_types.size > 1){
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Sequestration IDs should be of same type",
            })
          );
          return;
        }

        if (values.sequestration_ids.length === 0) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "At least one Sequestration ID is Required",
            })
          );
          return;
        }
        const sequestration_ids = values.sequestration_ids.map((id) => id.value);
        const obj : FormulationFormObjProps = {
          sequestration_ids: sequestration_ids,
          material_qty: values.material_qty,
          total_batch_qty: values.total_qty,
          product_name: values.product_name,
        }
        if (values.material_id !== undefined) {
          obj.material_id = values.material_id;
        }

        const result = await axiosInstance.post(
          `/suncarbon_product/create`,
          obj,
          { headers: { ContentType: "application/json" } }
        );
        console.log(result);

        queryClient.invalidateQueries(["getProductFormulation"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Product created successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setSubmitting(false);
    },
    [dispatch, queryClient]
  );

  const handleCancel = () => {
    dispatch(hideModal());
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form
          className="px-6 py-4 mt-2 mr-1 rounded-sm"
          style={{
            minWidth: "550px",
            maxWidth: "800px",
          }}
        >
          <div className="flex gap-5 justify-center ">

            <SelectDropDownAsync
              label="Select BioChar Batches"
              id="sequestration_ids"
              name="sequestration_ids"
              loadOptions={async (inferString: string) => {
                try {
                  const response =
                    await axiosInstance.get(
                      "/biomass_batch/sequestration/all?sp_id=null"
                    );
                  let requiredData: any = [];
                  for (
                    let i = 0;
                    i < response?.data?.data?.length;
                    i++
                  ) {
                    if (response?.data?.data[i]?.sequestration_id.toLowerCase().includes(inferString.toLowerCase())) {
                      requiredData.push({
                        label: response?.data?.data[i].sequestration_id,
                        value: response?.data?.data[i].sequestration_id,
                      });
                    }
                  }
                  return requiredData;
                } catch (error) {
                  return [
                    {
                      label: "Error. Something went wrong!!",
                      value: "error",
                    },
                  ];
                }
              }}
              isMulti={true}
            />
            
            <SelectDropDownAsync
              label="Add Material (Optional)"
              id="material_id"
              name="material_id"
              loadOptions={async (inferString: string) => {
                try {
                  const response =
                    await axiosInstance.get(
                      "/biomass_batch/sequestration/compost_material/all"
                    );
                  let requiredData: any = [];
                  for (
                    let i = 0;
                    i < response?.data?.data?.length;
                    i++
                  ) {
                    if (response?.data?.data[i]?.material_name.toLowerCase().includes(inferString.toLowerCase())) {
                      requiredData.push({
                        label: response?.data?.data[i].material_name,
                        value: response?.data?.data[i].material_id,
                      });
                    }
                  }
                  return requiredData;
                } catch (error) {
                  return [
                    {
                      label: "Error. Something went wrong!!",
                      value: "error",
                    },
                  ];
                }
              }}
            />
          </div>
          <div className="flex gap-5 justify-center mt-7">
            <Input
              label="Total Quantity (Kgs)"
              name="total_qty"
              id="total_qty"
              type="number"
            />
            
            <Input 
              label="Material Quantity (kgs)"
              id="material_qty"
              name="material_qty"
              type="number"
              placeholder="10 kg"
            />
            
          </div>
          <div className="flex gap-5 justify-center mt-7">
            <Input
              label="Product Name" name="product_name" id="product_name" type="text" placeholder="Product Name"
            />
          </div>
          <div className="buttons flex items-center justify-center gap-x-4 my-9">
            <button
              type="button"
              onClick={handleCancel}
              className="text-sm px-4 py-2 rounded font-bold w-full border border-black"
            >
              Cancel
            </button>
            <SubmitBtn
              text="Submit"
              isSubmitting={isSubmitting}
              classes="text-sm w-full"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormulationFormModal;
