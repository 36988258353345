import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface FPOObj {
  fpo_name: string;
  fpo_number: number;
  fpo_id: string;
}

type EditFPOModalProps = {
  fpo_name: string;
  fpo_number: number;
  fpo_id: string;
  // addressId: string;
};

const EditFPOModal: React.FC<EditFPOModalProps> = ({
  fpo_name,
  fpo_number,
  fpo_id,
  // addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: FPOObj = {
    fpo_name: fpo_name,
    fpo_number: fpo_number,
    fpo_id: fpo_id,
  };

  const validationSchema = Yup.object({
    fpo_name: Yup.string().required("Name is Required"),
    fpo_number: Yup.number().required("Number is Required"),
  });

  const handleSubmit = useCallback(
    async (fpo: FPOObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.patch(
          `/fpo/update`,
          {
            fpo_name: fpo.fpo_name,
            fpo_id: fpo_id,
            fpo_number: fpo.fpo_number,
            // addresses: [
            //   {
            //     addressId: addressId,
            //   },
            // ],
          },
          { headers: { ContentType: "application/json" } }
        );
        console.log(result);

        queryClient.invalidateQueries(["getAllFpos"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "FPO Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [queryClient, dispatch]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, handleChange }) => (
        <Form
          className="px-6 py-4 mt-2"
          style={{
            minWidth: "360px",
            maxWidth: "760px",
          }}
        >
          <Input label="Name" id="fpo_name" name="fpo_name" type="text" />

          <Input
            label="Contact"
            id="fpo_number"
            name="fpo_number"
            type="number"
          />

          {/* Add FPO ID here as a read-only field */}
          {/* <Input
            label="FPO ID"
            id="fpo_id"
            name="fpo_id"
            type="text"
            value={fpo_id}
            disabled={false} // make this field read-only
          /> */}

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              classes="text-sm"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditFPOModal;
