import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface BiomassConfigObj {
  biomassName: string;
  avgBiomassDia: number;
  avgBiomassLen: number;
  biomassPhoto: string;
  moistureMeterPhoto: string;
  cottonStock: number;
  riceStraw: number;
  test: string;
}

type EditBiomassConfigModalProps = {
  biomassConfigId: string;
  biomassName: string;
  avgBiomassDia: number;
  avgBiomassLen: number;
  biomassPhoto: string;
  moistureMeterPhoto: string;
  cottonStock: number;
  riceStraw: number;
  test: string;
  addressId: string;
};

const EditBiomassConfigModal: React.FC<EditBiomassConfigModalProps> = ({
  biomassConfigId,
  biomassName,
  avgBiomassDia,
  avgBiomassLen,
  biomassPhoto,
  moistureMeterPhoto,
  cottonStock,
  riceStraw,
  test,
  addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: BiomassConfigObj = {
    biomassName: biomassName,
    avgBiomassDia: avgBiomassDia,
    avgBiomassLen: avgBiomassLen,
    biomassPhoto: biomassPhoto,
    moistureMeterPhoto: moistureMeterPhoto,
    cottonStock: cottonStock,
    riceStraw: riceStraw,
    test: test,
  };

  const validationSchema = Yup.object({
    biomassName: Yup.string().required("Biomass Name is Required"),
    avgBiomassDia: Yup.number().required(
      "Average Biomass Diameter is Required"
    ),
    avgBiomassLen: Yup.number().required("Average Biomass Length is Required"),
    biomassPhoto: Yup.string().required("Biomass Photo is Required"),
    moistureMeterPhoto: Yup.string(),
    cottonStock: Yup.number().required("Cotton Stock is Required"),
    riceStraw: Yup.number().required("Rice Straw is Required"),
    test: Yup.string(),
  });

  const handleSubmit = useCallback(
    async (biomass_config: BiomassConfigObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.patch(
          `/biomass_composition/${biomassConfigId}`,
          {
            biomass_name: biomass_config.biomassName,
            average_biomass_diameter: biomass_config.avgBiomassDia,
            average_biomass_length: biomass_config.avgBiomassLen,
            biomass_photo: biomass_config.biomassPhoto,
            moisture_meter_photo: biomass_config.moistureMeterPhoto,
            biomass_blend: {
              cotton_stock: biomass_config.cottonStock,
              rice_straw: biomass_config.riceStraw,
              test: biomass_config.test,
            },
            addressId: addressId, 
          },
          { headers: { "Content-Type": "application/json" } }
        );
        console.log(result);

        queryClient.invalidateQueries(["getAllBiomassComposition"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Biomass Config Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [biomassConfigId, addressId, queryClient, dispatch]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, handleChange }) => (
        <Form
          className="px-6 py-4 mt-2"
          style={{
            minWidth: "360px",
            maxWidth: "760px",
          }}
        >
          <Input
            label="Biomass Name"
            id="biomassName"
            name="biomassName"
            type="text"
          />

          <h2 className="text-xl font-semibold">Biomass Blend Details</h2>
          <Input
            label="Cotton Stock"
            id="cottonStock"
            name="cottonStock"
            type="number"
            value={values.cottonStock}
            onChange={handleChange}
          />
          <Input
            label="Rice Straw"
            id="riceStraw"
            name="riceStraw"
            type="number"
            value={values.riceStraw}
            onChange={handleChange}
          />
          <Input
            label="Test"
            id="test"
            name="test"
            type="text"
            value={values.test}
            onChange={handleChange}
          />

          <Input
            label="Average Biomass Diameter"
            id="avgBiomassDia"
            name="avgBiomassDia"
            type="number"
          />

          <Input
            label="Average Biomass Length"
            id="avgBiomassLen"
            name="avgBiomassLen"
            type="number"
          />

          <Input
            label="Biomass Image"
            id="biomassPhoto"
            name="biomassPhoto"
            type="text"
          />
          <Input
            label="Moisture Meter Image"
            id="moistureMeterPhoto"
            name="moistureMeterPhoto"
            type="text"
          />

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              classes="text-sm"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditBiomassConfigModal;
