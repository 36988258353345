import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_ARTISAN_MODAL,
    APPROVE_ARTISAN_MODAL,
    ASSIGN_FPO_MODAL,
    EDIT_ALL_ARTISAN_MODAL,
} from "../../types/constants";
import { MdAdd } from "react-icons/md";
import useAssignFPO from "../../hooks/useAssignFPO";
import useIsAdmin from "../../hooks/useIsAdmin";
import { useLocation } from "react-router-dom";

interface UserObj {
    id: string;
    gstin: string;
    name: string;
    companyName: string;
    createdAt: string;
}

interface TableColumn {
    Header: string;
    accessor: string;
    Cell?: () => JSX.Element;
}

interface ArtisanProps {
    otherUrl?: string;
    otherFields?: TableColumn[];
}
const AllArtisan = ({ otherUrl, otherFields }: ArtisanProps) => {
    const { handleFPOAssign } = useAssignFPO();
    const { isAdmin } = useIsAdmin();

    const ALL_USERS_COLUMNS = [
        {
            Header: "Id",
            accessor: "artisan_id",
        },
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Address",
            accessor: "address",
        },
        {
            Header: "Company Email",
            accessor: "company_email",
        },
        {
            Header: "Company Mobile",
            accessor: "company_mobile",
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: any) => {
                return new Date(value).toLocaleString();
            },
        },
        // {
        //     Header: "KYC Image",
        //     accessor: "artisan_kyc_doc_url",
        //     Cell: ({ value }: any) => {
        //         return (
        //             <a
        //                 href={value}
        //                 className="text-green-400"
        //                 target="_blank"
        //                 rel="noreferrer"
        //             >
        //                 Open Image
        //             </a>
        //         );
        //         // return <img src={value} className="h-44" />
        //     },
        // },
        {
            Header: "Photo Site",
            accessor: "site_image_url",
            Cell: ({ value }: any) => {
                return (
                    <a
                        href={value}
                        className="text-green-400"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Open Image
                    </a>
                );
            },
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ value, row }: any) => {
                return value === "approved" ? (
                    "Yes"
                ) : (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: APPROVE_ARTISAN_MODAL,
                                    modalTitle: "Approve Artisan",
                                    modalProps: {
                                        data: row.original,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        Approve Artisan
                    </button>
                );
            },
        },

        //TODO: IMP

        {
            Header: "Edit",
            accessor: "edit",
            Cell: ({ value, row }: any) => {
                // console.table(row.original);
                return (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: EDIT_ALL_ARTISAN_MODAL,
                                    modalTitle: "Edit Artisan",
                                    modalProps: {
                                        artisanId: row?.original.id,
                                        name: row?.original.name,
                                        address: row?.original.address,
                                        companyEmail:
                                            row?.original.company_email,
                                        artisanKycDocUrl:
                                            row?.original.artisan_kyc_doc_url,
                                        companyMobile:
                                            row?.original.company_mobile,
                                        siteImageUrl:
                                            row?.original.site_image_url,
                                        addressId: row?.original.addressId,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        Edit Artisan
                    </button>
                );
            },
        },
    ];

    if (isAdmin) {
        ALL_USERS_COLUMNS.push({
            Header: "Assinged FPO",
            accessor: "fpo_id",
            Cell: ({ row }: any) => {
                const fpo_id = row.original.fpo_id || null;
                const fpo_name = row.original.fpo_name || null;
                return (
                    <>
                        {fpo_id && (
                            <p className="text-green-400">
                                {fpo_name} :
                                <br />({fpo_id})
                            </p>
                        )}
                        {!fpo_id && (
                            <p className="text-red-400">No FPO assigned</p>
                        )}
                    </>
                );
            },
        });
        ALL_USERS_COLUMNS.push({
            Header: "Assing/Unassign FPO",
            accessor: "updatedAt",
            Cell: ({ row }: any) => {
                const fpo_id = row.original.fpo_id || null;
                const is_fpo_details_page =
                    useLocation().pathname.includes("/fpo/details");
                return (
                    <>
                        <button
                            onClick={() => {
                                if (fpo_id) {
                                    handleFPOAssign({
                                        id: row.original.artisan_id,
                                        action: "unassign",
                                        user_role: "artisan",
                                        fpo_id: row.original.fpo_id,
                                    });
                                    return;
                                }
                                dispatch(
                                    showModal({
                                        modalType: ASSIGN_FPO_MODAL,
                                        modalTitle: "Assign FPO Modal",
                                        modalProps: {
                                            id: row.original.artisan_id,
                                            action: fpo_id
                                                ? "unassign"
                                                : "assign",
                                            role: "artisan",
                                            fpo_id: fpo_id,
                                        },
                                    })
                                );
                            }}
                            className={`${
                                fpo_id ? "bg-red-500" : "bg-green-600"
                            } text-white px-2 py-3`}
                        >
                            {is_fpo_details_page
                                ? "Unlink Artisan"
                                : fpo_id
                                ? "Unassign FPO"
                                : "Assign FPO"}
                        </button>
                    </>
                );
            },
        });
    }

    if (otherFields) {
        ALL_USERS_COLUMNS.push(...otherFields);
    }

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState("created_at");
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [, setSelectedRows] = useState([]);

    let component = null;

    const getArtisans = async () => {
        const { data } = await axiosInstance.get(
            otherUrl || `/artisan/all?status=pending`
        );
        return data?.data;
    };

    const { data, error, isLoading } = useQuery<
        UserObj[] | undefined,
        any,
        UserObj[],
        any[]
    >(["getArtisans", pageIndex, cPageSize, cSortBy, desc, q], getArtisans);

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1} // do calculation here
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    const [role, setRole] = useState(localStorage.getItem("role"));

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => {}}
                    />
                </div>
                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_ARTISAN_MODAL,
                                    modalTitle: "Add a Artisan",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>

            {component !== null && component}
        </div>
    );
};

export default AllArtisan;
