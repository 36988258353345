import React from "react";
import { BioCharFormData } from "../../types";

interface ViewPreparationModalProps {
  data: BioCharFormData;
}

const ViewBioCharModal = (props: ViewPreparationModalProps) => {
  const {
    vessel_name,
    biochar_image,
    biochar_weight,
    retention_bucket_img,
    sampling_date,
    sampling_image,
    vessel_qty,
  } = props.data;
  console.log(props);

  return (
    <div
      className="px-6 py-4 mt-2"
      style={{
        minWidth: "360px",
        maxWidth: "760px",
      }}
    >
      <div className="flex flex-row item-center gap-x-6">
        <div className="text-xl flex flex-col gap-4">
          <p>Measuring Vessel : {vessel_name}</p>
          <p>Vessel Quantity : {vessel_qty}</p>
          <p>BioChar Weight : {biochar_weight}</p>

          <p>
            BioChar Image:{" "}
            <a
              href={biochar_image}
              className="text-green-400"
              target="_blank"
              rel="noreferrer"
            >
              Open Image
            </a>
          </p>

          <p>Sampling Date : {sampling_date.slice(0, 10)}</p>

          <p>
            Sampling Image:{" "}
            <a
              href={sampling_image}
              className="text-green-400"
              target="_blank"
              rel="noreferrer"
            >
              Open Image
            </a>
          </p>

          <p>
            Retention Bucket Image:{" "}
            <a
              href={retention_bucket_img}
              className="text-green-400"
              target="_blank"
              rel="noreferrer"
            >
              Open Image
            </a>
          </p>
          
        </div>
      </div>
    </div>
  );
};

export default ViewBioCharModal;
