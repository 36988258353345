import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { uploadImage } from "../../http/uploadImage";

interface FormValues {
  vessel_name: string;
  manufacturer_name: string;
  vessel_type: string;
  volume: string;
}

const AddMesuringVesselModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [img, setImg] = useState<Object | null>(null);
  const initialValues: FormValues = {
    vessel_name: "",
    manufacturer_name: "",
    vessel_type: "",
    volume: "",
  };

  const validationSchema = Yup.object({
    vessel_name: Yup.string().required("Vessel Name is Required"),
    manufacturer_name: Yup.string().required("Manufacturer Name is Required"),
    vessel_type: Yup.string().required("Vessel Type is Required"),
    volume: Yup.string().required("Volume is Required"),
  });

  const handleSubmit = async (measuring_vessel: FormValues) => {
    if (!img) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Image is required",
        })
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const imgRes = await uploadImage(img);
      console.log(imgRes.data.publicUrl);
      if (imgRes.data.publicUrl) {
        const obj = {
          ...measuring_vessel,
          vessel_image: imgRes.data.publicUrl,
        };
        console.log(obj);
        const result = await axiosInstance.post(
          "/measuring_vessel/create",
          obj
        );
        if (result) {
          console.log(result);
          setIsSubmitting(false);
          queryClient.invalidateQueries(["getAllMeasuringVessel"]);
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Measuring Vessel Added Successfully!",
            })
          );
          dispatch(hideModal());
        }
      }
    } catch (error: any) {
      setIsSubmitting(false);

      if (error.response) {
        const response = error.response;
        const { msg } = response.data;

        switch (response.status) {
          // bad request or invalid format or unauthorized
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <div className="flex item-center gap-x-6">
          <Input
            label="Vessel Name"
            id="vessel_name"
            name="vessel_name"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Manufacturer Name"
            id="manufacturer_name"
            name="manufacturer_name"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Vessel Type"
            id="vessel_type"
            name="vessel_type"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Volume (please mention litre, ml)"
            id="volume"
            name="volume"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Vessel Image"
            id="vessel_image"
            name="vessel_image"
            type="file"
            accept=".jpg,.png,.jpeg,.wbep"
            disabled={!!img}
            onChange={(e) => {
              console.log(e);
              if (e.target.files) {
                setImg(e.target.files[0]);
              }
            }}
          />
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
        
      </Form>
    </Formik>
  );
};

export default AddMesuringVesselModal;
