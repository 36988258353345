import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import SelectDropDownAsync from "../FormikComponents/SelectDropDownAsync";
import Select from "../FormikComponents/Select";

interface SellerObj {
    user_id: string;
    sold_quantity: number;
    amount: number;
    payment_mode: string;
}

type SellToBuyerProps = {
    quantity: number;
    fpo_inventory_id: number;
    fpo_id: string;
    sp_id: string;
    product_name: string;
};

const SellToBuyer: React.FC<SellToBuyerProps> = ({
    quantity,
    fpo_inventory_id,
    fpo_id,
    sp_id,
    product_name,
}) => {


    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const initialValues: SellerObj = {
        sold_quantity: 0,
        amount: 0,
        payment_mode: "cash",
        user_id: "",
    };

    const validationSchema = Yup.object({
        sold_quantity: Yup.number().max(quantity, "Quantity exceeds available").min(1, "Quantity must be atleast 1").required("Quantity is required"),
        amount: Yup.number().min(1, "Amount Must be greater than 0").required("Amount is required"),
        user_id: Yup.string().required("User ID is required"),
        payment_mode: Yup.string().required("Payment Mode is required"),
    });

    const handleSubmit = useCallback(
        async (values: SellerObj) => {
            setIsSubmitting(true);
            try {
                const result = await axiosInstance.post(
                    `/fpo/inventory/sell`,
                    {
                        user_id: values.user_id,
                        sold_quantity: values.sold_quantity,
                        amount: values.amount,
                        payment_mode: values.payment_mode,
                        product_id: sp_id
                    },
                    { headers: { ContentType: "application/json" } }
                );
                console.log(result);

                queryClient.invalidateQueries("getFPOProducts");
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Product Sold Successfully",
                    })
                );
                
                dispatch(hideModal());
            } catch (error: any) {
                if (error.response) {
                    const response = error.response;
                    const { msg } = response.data;

                    switch (response.status) {
                        case 400:
                        case 500:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: msg,
                                })
                            );
                            break;
                        default:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "Oops, something went wrong",
                                })
                            );
                            break;
                    }
                } else if (error.request) {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: "Oops, something went wrong",
                        })
                    );
                } else {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: `Error: ${error.message}`,
                        })
                    );
                }
            }
            setIsSubmitting(false);
        },
        [dispatch, queryClient]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            {props => (
                <Form
                    className="px-6 py-4 mt-2"
                    style={{
                        minWidth: "50vw",
                        maxWidth: "90vw",
                    }}
                >
                    <div className="flex item-center gap-x-6">
                        <SelectDropDownAsync
                            label="User Name"
                            id="user_id"
                            name="user_id"
                            classes="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            loadOptions={async (inferString: string) => {
                                try {
                                    const response =
                                        await axiosInstance.get(
                                            "/admin/users"
                                        );
                                    let requiredData: any = [];
                                    for (
                                        let i = 0;
                                        i < response.data.data.length;
                                        i++
                                    ) {
                                        if (response.data.data[i].name.toLowerCase().includes(inferString.toLowerCase())) {
                                            requiredData.push({
                                                label: response?.data.data[i].name,
                                                value: response?.data.data[i].id,
                                            });
                                        }
                                    }
                                    return requiredData;
                                } catch (error) {
                                    return [
                                        {
                                            label: "Error. Something went wrong!!",
                                            value: "error",
                                        },
                                    ];
                                }
                            }}
                        />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 item-center gap-x-6 ">
                        <Input label="Available Quantity" id="available_quantity" name="available_quantity" type="text" value={quantity} className="border border-gray-400 cursor-not-allowed" disabled  />
                        <Input label="Batch ID" id="batch_id" name="batch_id" type="text" value={sp_id} className="border border-gray-400 cursor-not-allowed" disabled/>
                    </div>
                    <p className="text-sm -mt-3 mb-6 text-gray-700">This Details can not be changed (Read Only)</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 item-center gap-x-6">
                        <Input
                            label="Quantity Sold"
                            id="sold_quantity"
                            name="sold_quantity"
                            type="number"
                        />
                        <Input
                            label="Amount"
                            id="amount"
                            name="amount"
                            type="number"
                        />
                    </div>
                    <div className="flex w-full item-center gap-x-6">
                        <Select
                            label="Payment Mode"
                            id="payment_mode"
                            name="payment_mode"
                            options={[
                                { value: "cash", label: "Cash" },
                            ]}
                            classes="w-full"
                        />
                    </div>

                    <div className="buttons flex items-center w-full justify-center my-4">
                        <SubmitBtn
                            text="Save"
                            isSubmitting={isSubmitting}
                            classes="text-sm"
                        />
                    </div>
                </Form>
            )
            }

        </Formik>
    );
};

export default SellToBuyer;
