import React from 'react'

function InfoCard({ title, value }: { title: string, value: string|number|undefined }) {
  return (
    <div className='py-4 px-3 shadow-lg flex justify-between items-center bg-white'>
        <span>{title}</span>
        <span className='text-green-500'>{value}</span>
    </div>
  )
}

export default InfoCard