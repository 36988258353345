import { useField } from "formik";
import React, { useEffect } from "react";
import { Option } from "../../types";

interface Props {
  label: string;
  id: string;
  name: string;
  options: Option[];
  selected?: string;
  inline?: boolean;
  classes?: string;
  value?: string;
}

const SimpleSelect = ({
  label,
  id,
  name,
  options = [],
  selected,
  classes,
  inline,
  value,
  ...props
}: Props) => {

  // props -> every props except label and options -> { name: 'value', id: 'value' }
  // const [field, meta, helpers] = useField(name);

  // useEffect(() => {
  //   if (options.length > 0 && !field.value) {
  //     const exists = selected
  //       ? options.find((o) => o.value === selected)
  //       : undefined;

  //     // if selected is given and it is also found in the given options
  //     if (exists) {
  //       helpers.setValue(exists.value);
  //     }
  //   }
  // }, [options]);

  return (
    <div className={`flex flex-col mb-6 ${classes}`}>
      <div
        className={`form-group flex ${
          inline ? " flex-row items-center" : "flex-col justify-center"
        }`}
      >
        <label
          className={`font-semibold ${inline ? "mr-4 w-28" : "mb-2"}`}
          htmlFor={id}
        >
          {label}
        </label>

        <select
          disabled={options.length === 0}
          {...props}
          className={`border focus:border-primary border-coolGray-200`}
          value={value}
        >
          <option key="default" value="">
            Select an {label}
          </option>

          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
          
        </select>

      </div>
      {/* {meta.touched && meta.error && <ErrorBox msg={meta.error} />} */}
    </div>
  );
};

export default SimpleSelect;
