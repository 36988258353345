import React from "react";
import { useLocation } from "react-router-dom";

import getPageTitle from "../../utils/getPageTitle";
import ProfileCard from "./ProfileCard";

const Header = () => {
  const { pathname } = useLocation();

  return (
    <header className="header z-10 bg-transparent flex items-center justify-between px-8 pl-8 py-5 text-neutral-700">
      <div className="left flex items-center justify-start">
        <h1 className="text-2xl font-semibold">{getPageTitle(pathname)}</h1>
      </div>

      <div className="right flex items-center gap-x-4">
        <ProfileCard />
      </div>

    </header>
  );
};

export default Header;
