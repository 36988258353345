import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { NewICAR } from "../../types";
import SimpleInput from "../InputComponents/SimpleInput";
import SimpleSelect from "../InputComponents/SimpleSelect";

type ICARProps = {
  id: string;
} & NewICAR;

type UpdateProps = Omit<NewICAR, "cropId" | "nutrient">;

const EditICARModal = ({
  cropId,
  cropType,
}: {
  cropId: string;
  cropType: string;
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cropNutrientsData, setCropNutrientsData] = useState<
    ICARProps[] | null
  >(null);

  const handleICARUpdate = async (id: string, icarData: ICARProps) => {
    const obj: UpdateProps = {
      // nutrient: icarData.nutrient,
      min: +icarData.min,
      max: +icarData.max,
      avg: +icarData.avg,
      unit: icarData.unit,
      // age: icarData.age,
      cropType: cropType,
      // treeCount: icarData.treeCount
    };
    console.log(obj);
    try {
      setIsSubmitting(true);
      const res = await axiosInstance.patch(
        "/fertilizer/icar-recommendation/update/" + id,
        obj
      );
      console.log(res);

      dispatch(addToast({ kind: "SUCCESS", msg: "ICAR updated successfully" }));
      // dispatch(hideModal());
    } catch (error: any) {
      console.log(error);
    }
    setIsSubmitting(false);
  };

  const handleChange = (index: number, key: string, value: string) => {
    const updatedData = cropNutrientsData?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });
    if (updatedData) setCropNutrientsData(updatedData);
  };

  useEffect(() => {
    const getCropICAR = async () => {
      try {
        const res = await axiosInstance.post(
          "/fertilizer/icar-recommendation",
          {
            cropId: cropId,
          }
        );
        setIsError(false);
        console.log("here", res.data.data);

        setCropNutrientsData(res.data.data.cropNutrientsData);
      } catch (error: any) {
        console.log(error);
        
        switch (error.response.status) {
          case 500:
            setIsError(true);
            break;
        }
      }
    };
    getCropICAR();
  }, []);

  return (
    <div>
      {!isError ? (
        cropNutrientsData ? (
          cropNutrientsData.map((_, key) => (
            <div
              className="px-6 py-4 mt-2"
              style={{
                minWidth: "360px",
                maxWidth: "760px",
              }}
            >
              <div className="flex flex-row item-center gap-x-6">
                <div>
                  <SimpleInput
                    label="Nutrient Name"
                    id="nutrient"
                    name="nutrient"
                    type="text"
                    value={_.nutrient}
                    onChange={(e) => {
                      handleChange(key, "nutrient", e);
                    }}
                    readonly
                  />
                </div>

                <div>
                  <SimpleInput
                    label="Minimum"
                    id="min"
                    name="min"
                    type="number"
                    value={_.min.toString()}
                    onChange={(e) => {
                      handleChange(key, "min", e);
                    }}
                  />
                </div>

                <div>
                  <SimpleInput
                    label="Maximum"
                    id="max"
                    name="max"
                    type="number"
                    value={_.max.toString()}
                    onChange={(e) => {
                      handleChange(key, "max", e);
                    }}
                  />
                </div>

                <div>
                  <SimpleInput
                    label="Average"
                    id="avg"
                    name="avg"
                    type="number"
                    value={_.avg.toString()}
                    onChange={(e) => {
                      handleChange(key, "avg", e);
                    }}
                  />
                </div>

                {/* {cropType === "fruit" && _.age && <div>
                <SimpleInput
                  label="Age"
                  id="age"
                  name="age"
                  type="number"
                  value={_.age.toString()}
                  onChange={(e) => {
                    handleChange(key, "age", e);
                  }}
                />
              </div>}

              {cropType === "fruit" && _.treeCount && <div>
                <SimpleInput
                  label="Tree Count"
                  id="treeCount"
                  name="treeCount"
                  type="number"
                  value={_.treeCount.toString()}
                  onChange={(e) => {
                    handleChange(key, "treeCount", e);
                  }}
                />
              </div>} */}

                <div className="w-48">
                  <SimpleSelect
                    options={[{ value: "kg/hectare", label: "kg/hectare" }]}
                    label="Unit"
                    id="unit"
                    name="unit"
                    value={_.unit}
                  />
                </div>

                <div className="flex justify-center items-center">
                  <button
                    className="bg-green-600 text-white px-2 py-2"
                    onClick={() => {
                      handleICARUpdate(_.id, cropNutrientsData[key]);
                    }}
                    disabled={isSubmitting}
                  >
                    Update
                  </button>
                </div>
                
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center px-40 py-4 mt-2 h-60">
            <p className="text-xl">Loading....</p>
          </div>
        )
      ) : (
        <div className="flex items-center justify-center px-6 py-4 mt-2 h-60">
          <p className="text-red-600 text-xl">
            No ICAR data found for this crop
          </p>
        </div>
      )}
    </div>
  );
};

export default EditICARModal;
