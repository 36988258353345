import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { NewFertilizer } from "../../types";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";

const AddFertiliserModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: NewFertilizer = {
    type: "simple",
    name: "",
    nitrogen: 0,
    phosphorus: 0,
    potassium: 0,
    sulphur: 0,
    calcium: 0,
    category: "organic",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Fertiliser name is Required"),
    type: Yup.string().required("Fertiliser type is Required"),
    nitrogen: Yup.number().required("Nitrogen value is Required"),
    phosphorus: Yup.number().required("Phosphorus value is Required"),
    potassium: Yup.number().required("Potassium value is Required"),
    category: Yup.string().required("Fertiliser category is Required"),
    sulphur: Yup.number(),
    calcium: Yup.number(),
  });

  const handleSubmit = useCallback(
    (
      fertiliser: NewFertilizer,
      {
        setFieldError,
      }: { setFieldError: (field: string, message: string) => void }
    ) => {
      setIsSubmitting(true);

      axiosInstance
        .post(`/users/fertilizers/add`, fertiliser, {
          headers: {
            ContentType: "application/json",
          },
        })
        .then((response) => {
          const { msg } = response.data;

          setIsSubmitting(false);

          queryClient.invalidateQueries(["getFertilisers"]);
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Fertiliser Added Successfully!",
            })
          );

          dispatch(hideModal());
        })
        .catch((error: AxiosError) => {
          setIsSubmitting(false);

          if (error.response) {
            const response = error.response;
            const { msg, error: errorMsg } = response.data;

            if (
              errorMsg &&
              errorMsg.includes(
                "duplicate key value violates unique constraint"
              )
            ) {
              setFieldError(
                "name",
                "A fertiliser with this name already exists. Please choose a different name."
              );
            } else {
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg || "An error occurred while adding the fertiliser.",
                })
              );
            }
          } else if (error.request) {
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Network error. Please check your connection and try again.",
              })
            );
          } else {
            dispatch(
              addToast({
                kind: ERROR,
                msg: `Error: ${error.message}`,
              })
            );
          }
        });
    },
    [dispatch, queryClient]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form
          className="px-6 py-4 mt-2"
          style={{
            minWidth: "360px",
            maxWidth: "760px",
          }}
        >
          <div className="flex flex-col gap-y-4">
            <Input label="Fertiliser Name" id="name" name="name" type="text" />
            {errors.name && touched.name && (
              <div className="text-red-500">{errors.name}</div>
            )}

            <Select
              options={[
                { value: "simple", label: "Simple" },
                { value: "complex", label: "Complex" },
              ]}
              label="Type"
              id="type"
              name="type"
            />

            <Select
              options={[
                { value: "organic", label: "Organic" },
                { value: "inorganic", label: "Inorganic" },
              ]}
              label="Category"
              id="category"
              name="category"
            />

            <Input
              label="Nitrogen"
              id="nitrogen"
              name="nitrogen"
              type="number"
            />
            <Input
              label="Phosphorous"
              id="phosphorus"
              name="phosphorus"
              type="number"
            />
            <Input
              label="Potassium"
              id="potassium"
              name="potassium"
              type="number"
            />
            <Input label="Sulphur" id="sulphur" name="sulphur" type="number" />
            <Input label="Calcium" id="calcium" name="calcium" type="number" />
          </div>

          <div className="buttons flex items-center w-full justify-center mt-6">
            <SubmitBtn
              text="Add Fertiliser"
              isSubmitting={isSubmitting}
              classes="text-sm"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddFertiliserModal;
