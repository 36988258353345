import { useState } from "react";

const useIsAdmin = () => {
    const [role] = useState(localStorage.getItem("role") || "user");

    return {
        isAdmin: role === "admin",
        isUser: role === "user",
        isFpo: role === "fpo",
    };
};

export default useIsAdmin;
