import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Select from "../FormikComponents/Select";

interface SeedObj {
  seedType: string;
  brandName: string;
  unit: string;
  price: string;
}

const AddSeedVarietyModal = ({ cropId }: { cropId: string }) => {
  console.log(cropId);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: SeedObj = {
    seedType: "",
    brandName: "",
    unit: "",
    price: "",
  };

  const validationSchema = Yup.object({
    seedType: Yup.string().required("Seed Type is Required"),
    brandName: Yup.string().required("Brand Name is Required"),
    unit: Yup.string().required("Unit is Required"),
    price: Yup.string().required("Price is Required"),
  });

  const handleSubmit = useCallback((value: SeedObj) => {
    setIsSubmitting(true);

    axiosInstance
      .post(
        `/farming/crops/seedtypes`,
        {
          seedType: value.seedType,
          brandName: value.brandName,
          price: value.price,
          unit: value.unit,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getCrops"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Seed Variety Added Successfully!",
          })
        );

        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <div className="flex item-center gap-x-6">
          <Input label="Seed Type" id="seedType" name="seedType" type="text" />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Seed Brand"
            id="brandName"
            name="brandName"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input label="Price" id="price" name="price" type="number" />
        </div>

        <Select
          options={[
            { label: "KG", value: "KG" },
            { label: "TON", value: "TON" },
            { label: "QUINTAL", value: "QUINTAL" },
            { label: "GRAM", value: "GRAM" },
          ]}
          label="Unit"
          id="unit"
          name="unit"
        />

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
        
      </Form>
    </Formik>
  );
};

export default AddSeedVarietyModal;
