import { useState } from "react";
import Input from "../FormikComponents/Input";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR } from "../../types/constants";
import { useDispatch } from "react-redux";

interface AddNewFieldsProps {
  handleAdd: (title: string, value: string) => void;
  handleCancel: () => void;
  fieldValueType?: React.InputHTMLAttributes<HTMLInputElement>["type"];
}

const AddNewFields = (props: AddNewFieldsProps) => {
  const { handleAdd, handleCancel, fieldValueType = "number" } = props;
  const dispatch = useDispatch();
  const [fieldName, setFieldName] = useState("");
  const [fieldValue, setFieldValue] = useState("");

  const handleSubmit = () => {
    if (fieldValue === "" || fieldName === "") {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Field Name and Field Value are required",
        })
      );
    }
    handleAdd(fieldName, fieldValue);
  };

  return (
    <div className="flex flex-col item-center w-full border border-primary p-4">
      <Input
        label="Field Name"
        id="field_name"
        name="field_name"
        type="text"
        value={fieldName}
        onChange={(e) => setFieldName(e.target.value)}
      />

      <Input
        label="Field Value"
        id="new_field"
        name="new_field"
        type={fieldValueType}
        value={fieldValue}
        onChange={(e) => setFieldValue(e.target.value)}
      />

      <div className="flex justify-center items-center gap-10">
        <button
          type="button"
          onClick={handleSubmit}
          className="my-2 border-2 text-white bg-primary p-1 w-full"
        >
          Save{" "}
        </button>

        <button
          type="button"
          onClick={handleCancel}
          className="my-2 border-2 border-primary p-1 w-full"
        >
          Cancel
        </button>

      </div>

    </div>
  );
};

export default AddNewFields;
