import { HiOutlineSearch } from "react-icons/hi";
import React from "react";

interface Props {
  searchValue: string;
  setSearchValue: Function;
  handleSearch: Function;
  placeholder?: string;
}

const SearchBox = ({ searchValue, setSearchValue, handleSearch, placeholder }: Props) => {
  return (
    <form
      className="flex items-center p-4 mr-4 text-base bg-white border rounded-xl"
      onSubmit={() => handleSearch()}
    >
      <input
        type="text"
        placeholder={placeholder || "Search"}
        className="bg-transparent outline-none"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <button type="submit">
        <HiOutlineSearch className="icon-md" />
      </button>
      
    </form>
  );
};

export default SearchBox;
