import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { uploadImage } from "../../http/uploadImage";

interface FormValues {
  kiln_name: string;
  kiln_type: string;
  depth: number;
  upper_diameter: number;
  lower_diameter: number;
  address: string;
}

const AddPyrolysisKilnModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [img, setImg] = useState<Object | null>(null);
  const initialValues: FormValues = {
    kiln_name: "",
    kiln_type: "",
    depth: 0,
    upper_diameter: 0,
    lower_diameter: 0,
    address: "",
  };

  const validationSchema = Yup.object({
    kiln_name: Yup.string().required("Required"),
    kiln_type: Yup.string().required("Required"),
    depth: Yup.number().required("Required"),
    upper_diameter: Yup.number().required("Required"),
    lower_diameter: Yup.number().required("Required"),
    address: Yup.string().required("Required"),
  });

  const handleSubmit = async (kiln: FormValues) => {
    let lat = "12.971599";
    let long = "77.594566";
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition((pos) => {
        lat = pos.coords.latitude.toString();
        long = pos.coords.longitude.toString();
      });
    }
    if (!img) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Image is required",
        })
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const imgRes = await uploadImage(img);
      console.log(imgRes.data.publicUrl);

      if (imgRes.data.publicUrl) {
        const obj = {
          ...kiln,
          kiln_photo: imgRes.data.publicUrl,
          lat: lat,
          lng: long,
        };
        console.log(obj);

        const result = await axiosInstance.post("/pyrolysis_kiln/create", obj);
        if (result) {
          console.log(result);

          setIsSubmitting(false);
          queryClient.invalidateQueries(["getAllPyrolysisKiln"]);
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Pyrolysis kiln added successfully!",
            })
          );
          dispatch(hideModal());
        }
      }
    } catch (error: any) {
      setIsSubmitting(false);

      if (error.response) {
        const response = error.response;
        const { msg } = response.data;

        switch (response.status) {
          // bad request or invalid format or unauthorized
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <div className="flex item-center gap-x-6">
          <Input
            label="Kiln Name"
            id="kiln_name"
            name="kiln_name"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Kiln Type"
            id="kiln_type"
            name="kiln_type"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input label="Depth (in cm)" id="depth" name="depth" type="number" />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Upper Diameter (in cm)"
            id="upper_diameter"
            name="upper_diameter"
            type="number"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Lower Diameter (in cm)"
            id="lower_diameter"
            name="lower_diameter"
            type="number"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input label="Address" id="address" name="address" type="text" />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="kiln_photo"
            id="kiln_photo"
            name="kiln_photo"
            type="file"
            accept=".jpg,.png,.jpeg,.wbep"
            disabled={!!img}
            onChange={(e) => {
              console.log(e);
              if (e.target.files) {
                setImg(e.target.files[0]);
              }
            }}
          />
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
        
      </Form>
    </Formik>
  );
};

export default AddPyrolysisKilnModal;
