import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ADD_FERTILISER_MODAL,
  EDIT_ALL_FERTILISERS_MODAL,
} from "../../types/constants";

interface UserObj {
  name: string;
  nitrogen: number;
  phosphorus: number;
  potassium: number;
  category: string;
  type: string;
  id: string;
}

const AllFertilisers = () => {
  const queryClient = useQueryClient();

  // const handleApproveProduct = (id: string) => {
  //   axiosInstance.get(`/admin/products/${id}/approve`).then((res: any) => {
  //     console.log(res);
  //     toast.success("Product Approved Successfully!");
  //     queryClient.invalidateQueries("getFertilisers");
  //   });
  // };

  const ALL_USERS_COLUMNS = [
    {
      Header: "Fertilizer Name",
      accessor: "name",
    },
    {
      Header: "Nitrogen",
      accessor: "nitrogen",
    },
    {
      Header: "Phosphorus",
      accessor: "phosphorus",
    },
    {
      Header: "Potassium",
      accessor: "potassium",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Type",
      accessor: "type",
    },

    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ value, row }: any) => {
        console.table(row.original);
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_ALL_FERTILISERS_MODAL,
                  modalTitle: "Edit Fertiliser",
                  modalProps: {
                    fertiliserId: row?.original.id,
                    name: row?.original.name,
                    nitrogen: row?.original.nitrogen,
                    phosphorus: row?.original.phosphorus,
                    potassium: row?.original.potassium,
                    category: row?.original.category,
                    type: row?.original.type,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            Edit Fertiliser
          </button>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getFertilisers = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/users/fertilizers/all`);
    console.log(data);
    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(["getFertilisers", pageIndex, cPageSize, cSortBy, desc, q], getFertilisers);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => {}}
          />
        </div>

        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_FERTILISER_MODAL,
                  modalTitle: "Add a Fertiliser",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default AllFertilisers;
