import React from 'react'
import { APIProvider, ControlPosition, Map, MapControl, MapEvent, Marker } from "@vis.gl/react-google-maps";
import { useFormikContext } from 'formik';
import { GOOLGLE_MAPS_API_KEY } from '../../config';

function CenterMarker({latName, lngName, precision = 5}: {latName: string, lngName: string, precision?: number}) {
    const formref = useFormikContext();
    const { setFieldValue, values }: {
        values: any, setFieldValue: (name: string, value: string) => void} = formref;

        console.log(values)
    return (
        <div className="w-full h-96 rounded-xl">
            <APIProvider apiKey={GOOLGLE_MAPS_API_KEY as string}>
                <Map
                    style={{ width: '100%', height: '100%' }}
                    defaultCenter={{ lat: 0, lng: 0 }}
                    defaultZoom={3}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    id="map-id"
                    onZoomChanged={(e: MapEvent) => {
                        const center = e.map.getCenter();
                        const lat = center?.lat();
                        const lng = center?.lng();
                        setFieldValue(latName, lat?.toFixed(precision) as string);
                        setFieldValue(lngName, lng?.toFixed(precision) as string);
                    }}
                    onDrag={(e: MapEvent) => {
                        const center = e.map.getCenter();
                        const lat = center?.lat();
                        const lng = center?.lng();
                        setFieldValue(latName, lat?.toFixed(precision) as string);
                        setFieldValue(lngName, lng?.toFixed(precision) as string);
                    }}
                >
                    <MapControl position={ControlPosition.TOP_LEFT}>
                        <Marker
                            position={{ lat: values[latName] ? parseFloat(values[latName]) : 0, lng: values[lngName] ? parseFloat(values[lngName]) : 0 }}
                            draggable={false}
                        />
                    </MapControl>
                </Map>
            </APIProvider>
        </div>
    )
}

export default CenterMarker